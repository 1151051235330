'use strict';

(function() {
  function TurbineResource($resource) {
    return $resource('api/v1/turbines/:id/:controller/:page/:limit/:sortBy/:order', {
      id: '@_id'
    }, {
      update: {
        method: 'PUT'
      },
      moveToSite: {
        method: 'PUT',
        params: {
          controller: 'move'
        }
      },
      archive: {
        method: 'PUT',
        params: {
          controller: 'archive'
        }
      },
      merge: {
        method: 'PUT',
        params: {
          id: 'merge'
        }
      },
      getBladesByTurbine: {
        method: 'GET',
        params: {
          controller: 'get-blades'
        }
      },
    });
  }

  angular.module('windmanagerApp')
    .factory('Turbine', TurbineResource);

})();
